<template>
  <div class="about">
    <div class="wrap">
      <h2 data-aos="fade">«Соколятко»</h2>
      <p data-aos="fade-down" class="subname">
        Комунальне підприємство «Дитячий заклад оздоровлення та відпочинку «Соколятко» Бердичівської міської ради
      </p>

      <p data-aos="fade-down" data-aos-anchor-placement="top-center">
        У цього миловидного, розкішного й величавого лісу ніколи б не було історії, у цього лісу був би лише вік, тай
        годі. Табір «Соколятко» оживив цей розлогий дубняк, надав йому вигляду цивілізованого куточка відпочинку і, як у
        кожної структури, створеної людьми, у нього є своя біографія, доля, шлях.
        <br />
        …Важкий повоєнний 1947… Тут, поблизу Скраглівки, розміщено наметове містечко, а його мешканцями стали діти, чиї
        батьки не повернулися з фронтових доріг Другої світової війни. Згодом тут почали будуватися житлові та складські
        приміщення, їдальня, медпункт, адмінкорпус, лазня та клуб.
        <br />

        За роки існування табору, мабуть, що кожен другий бердичівлянин, хоча б раз та відпочивав тут. У свій час табір
        - приймав на оздоровлення дітей з Польщі, Болгарії, Узбекистану; дітей будівельників Байкало-Амурської
        магістралі, дітей, потерпілих від наслідків аварії на Чорнобильській АЕС.
        <br />

        Хочеться щоразу бувати тут, молодіти душею і збагачувати себе спілкуванням з прекрасними людьми і чудовою
        природою.
        <br />

        Нині повноправним господарем КП «Дитячий заклад оздоровлення та відпочинку «Соколятко» є Бердичівська міська
        територіальна громада.
      </p>

      <div class="gallery" data-aos="fade-down" data-aos-delay="500">
        <img src="@/assets/photo/pg1.jpg" alt="gallery" />
        <img src="@/assets/photo/pg2.jpg" alt="gallery" />
        <img src="@/assets/photo/pg3.jpg" alt="gallery" />
        <img src="@/assets/photo/pg4.jpg" alt="gallery" />
        <img src="@/assets/photo/pg5.jpg" alt="gallery" />
        <img src="@/assets/photo/pg6.jpg" alt="gallery" />
        <img src="@/assets/photo/pg7.jpg" alt="gallery" />
        <img src="@/assets/photo/pg8.jpg" alt="gallery" />
        <img src="@/assets/photo/pg9.jpg" alt="gallery" />
        <img src="@/assets/photo/pg10.jpg" alt="gallery" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .about {
    .wrap {
      .subname {
        font-size: 1.2em;
      }

      .gallery {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 8px;
        place-items: center;
        margin-top: 32px;

        img {
          position: relative;
          display: block;
          width: 100%;
          border-radius: 16px;
        }
      }
    }
  }
</style>
